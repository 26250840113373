<template>
  <div id="salary" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">
        PREGUNTAS CLIMA LABORAL
      </v-row>
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="guia" class="ml-5 mr-2">Guía de Referencia</label>
            <select
              name="guia"
              id="guia"
              v-model="referencia"
              @change="listar()"
            >
              <option selected disabled value="0">Selecciona</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addQuestionGuia" id="nuevaPreguntaempleado">
              <button class="breakSearch botonAmarillo">
                Alta de Pregunta
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="datos pa-0"
        style="height: auto !important"
        v-if="referencia == 1"
      >
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers2"
            :items="questions"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Preguntas por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">
                    {{ item.consecutivoGuiaPregunta }}
                  </td>
                  <td class="text-sm-center">{{ item.pregunta }}</td>
                  <td class="text-sm-center">{{ item.seccion }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                    >
                      fa-edit
                    </v-icon>
                    <!--
                    <v-icon color="red" small @click="temporal(item)">
                      fa-trash-alt
                    </v-icon>
                    -->
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="4">
                    <div class="text-h6 font-weight-bold text-center">
                      No hay datos en el sistema
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <v-row
        no-gutters
        class="datos pa-0"
        style="height: auto !important"
        v-else
      >
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="questions"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Preguntas por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning"
                >No hay datos en sistema ..</v-alert
              >
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">
                    {{ item.consecutivoGuiaPregunta }}
                  </td>
                  <td class="text-sm-center">{{ item.pregunta }}</td>
                  <td class="text-sm-center">
                    {{ item.fechaUltimaPublicacion }}
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                    >
                      fa-edit
                    </v-icon>
                    <!--
                    <v-icon color="red" small @click="temporal(item)">
                      fa-trash-alt
                    </v-icon>
                    -->
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="4">
                    <div class="text-h6 font-weight-bold text-center">
                      No hay datos en el sistema
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              advice = false;
              deleteItem();
            "
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Pregunta Clima Laboral</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "consecutivoGuiaPregunta",
          sortable: false,
        },
        {
          text: "Pregunta",
          align: "center",
          value: "pregunta",
        },
        {
          text: "Última fecha de publicación",
          align: "center",
          value: "fechaUltimaPublicacion",
        },
        {
          text: "Editar  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headers2: [
        {
          text: "N°",
          align: "center",
          value: "consecutivoGuiaPregunta",
          sortable: false,
        },
        {
          text: "Pregunta",
          align: "center",
          value: "pregunta",
        },
        {
          text: "Sección",
          align: "center",
          value: "seccion",
        },
        {
          text: "Editar  Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      questions: [],
      respuesta: "",
      advice: false,
      id: "",
      error: false,
      confirmation: false,
      referencia: 0,
      question: "",
      redacto: "",
      reason: 0,
      area: 0,
      areas: [],
      guia: 0,
    };
  },
  methods: {
    editItem(item) {
      this.$router.push(`/editQuestionGuia/${item.id}`);
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9?¿ ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    /*
      temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
      guarda solo el id del dato
    */
    temporal(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el elemento " + item.pregunta + " ?";
      this.advice = true;
    },
    deleteItem() {
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(`${Server}/climaLaboralPreguntas/${this.id}`, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then(() => {
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(`${Server}/climaLaboralPreguntas/guia/${this.referencia}`, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.questions = response.data;
          this.show = false;
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
  },
  mounted() {
    //this.listar();
  },
};
</script>
